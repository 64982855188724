



































import { defineComponent, ref } from "@vue/composition-api"

import { WeveTooltip } from "@weve/ui"
import { RtbControlButton } from "@/components/ui"

export default defineComponent({
  name: "MobileDrawingPicker",
  props: {
    hint: {
      type: String,
      default: "Tap here to choose brush size & color or erase"
    }
  },
  components: {
    WeveTooltip,
    RtbControlButton,
    DrawingPicker: () =>
      import("@/components/GroupTeams/Common/GameModes/DrawingPicker.vue")
  },
  setup() {
    const drawingPalette = ref(false)
    const brushHint = ref(true)

    return { drawingPalette, brushHint }
  }
})
