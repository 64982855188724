var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mobile-drawing-picker" },
    [
      _c(
        "WeveTooltip",
        {
          attrs: {
            variant: "light",
            placement: "bottomleft",
            triggers: "manual",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var attrs = ref.attrs
                return [
                  _c(
                    "RtbControlButton",
                    _vm._b(
                      {
                        staticClass: "mobile-btn mobile-btn--bottom-right",
                        on: {
                          click: function ($event) {
                            _vm.drawingPalette = !_vm.drawingPalette
                            _vm.brushHint = false
                          },
                        },
                      },
                      "RtbControlButton",
                      attrs,
                      false
                    ),
                    [_c("v-icon", { attrs: { dark: "" } }, [_vm._v("brush")])],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.brushHint,
            callback: function ($$v) {
              _vm.brushHint = $$v
            },
            expression: "brushHint",
          },
        },
        [
          _c("div", { staticClass: "text-sm-left" }, [
            _vm._v(" " + _vm._s(_vm.hint) + " "),
          ]),
        ]
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "content-class": "mobile-drawing-picker__palette",
            transition: "slide-x-reverse-transition",
          },
          model: {
            value: _vm.drawingPalette,
            callback: function ($$v) {
              _vm.drawingPalette = $$v
            },
            expression: "drawingPalette",
          },
        },
        [
          _c(
            "DrawingPicker",
            _vm._b(
              { attrs: { decorated: false } },
              "DrawingPicker",
              _vm.$attrs,
              false
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }